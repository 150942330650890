import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { LABEL_MODULES } from '../../setting/label-setting/table-conf';
import { SharedService } from '../shared.service';
import { LabelDTO } from '../../model';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-label-edit',
    templateUrl: './label-edit.component.html',
    styleUrls: ['./label-edit.component.scss'],
    imports: [
        MatToolbar,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatButtonToggleGroup,
        MatButtonToggle,
        ColorPickerModule,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ]
})
export class LabelEditComponent implements OnInit {
	data = inject(MAT_DIALOG_DATA);
	private _fb = inject(FormBuilder);
	private _service = inject(SharedService);
	private _dref = inject<MatDialogRef<any>>(MatDialogRef);

	labelForm: FormGroup;
	labelModules = LABEL_MODULES;
	color: string = '#987987';

	constructor() {
		this.labelForm = this._fb.group({
			id: '',
			value: [
				'',
				Validators.compose([
					Validators.required,
					Validators.maxLength(16),
				]),
			],
			description: '',
			color: '#987897',
			module: 'ALL',
		});
	}

	ngOnInit(): void {
		const data = this.data || new LabelDTO();
		this.color = data.color;
		this.labelForm.patchValue(this.data);
	}

	save(label) {
		label.color = this.color;
		this._service.saveLabel(label).subscribe(value => {
			if (value) this._dref.close(value);
		});
	}
}
